
.App {
  /* text-align: center; */

}
.App-content{
  /* margin-top:64px; */
  overflow: auto;
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 1024px !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Error-message {
  color: red;
  font-size: 13px
}
.MuiIconButton-edgeEnd{
  margin-right: 0 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.error_field{
  color:red;
}
