

body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  /* font-family: system, -apple-system, system-ui, BlinkMacSystemFont, "Roboto",
    "Helvetica Neue", "Lucida Grande", "Segoe UI", "Ubuntu", "Cantarell",
    "Fira Sans", sans-serif !important; */
    font-family: "Google Sans", sans-serif !important;
}
html {
  /* Material Design color palette for Google products */
  --google-red-100: #f4c7c3;
  --google-red-300: #e67c73;
  --google-red-500: #db4437;
  --google-red-700: #c53929;

  --google-blue-100: #c6dafc;
  --google-blue-300: #7baaf7;
  --google-blue-500: #4285f4;
  --google-blue-700: #3367d6;

  --google-green-100: #b7e1cd;
  --google-green-300: #57bb8a;
  --google-green-500: #0f9d58;
  --google-green-700: #0b8043;

  --google-yellow-100: #fce8b2;
  --google-yellow-300: #f7cb4d;
  --google-yellow-500: #f4b400;
  --google-yellow-700: #f09300;

  --google-grey-100: #f5f5f5;
  --google-grey-300: #e0e0e0;
  --google-grey-500: #9e9e9e;
  --google-grey-700: #616161;

  /* Material Design color palette from online spec document */
  --paper-red-50: #ffebee;
  --paper-red-100: #ffcdd2;
  --paper-red-200: #ef9a9a;
  --paper-red-300: #e57373;
  --paper-red-400: #ef5350;
  --paper-red-500: #f44336;
  --paper-red-600: #e53935;
  --paper-red-700: #d32f2f;
  --paper-red-800: #c62828;
  --paper-red-900: #b71c1c;
  --paper-red-a100: #ff8a80;
  --paper-red-a200: #ff5252;
  --paper-red-a400: #ff1744;
  --paper-red-a700: #d50000;

  --paper-pink-50: #fce4ec;
  --paper-pink-100: #f8bbd0;
  --paper-pink-200: #f48fb1;
  --paper-pink-300: #f06292;
  --paper-pink-400: #ec407a;
  --paper-pink-500: #e91e63;
  --paper-pink-600: #d81b60;
  --paper-pink-700: #c2185b;
  --paper-pink-800: #ad1457;
  --paper-pink-900: #880e4f;
  --paper-pink-a100: #ff80ab;
  --paper-pink-a200: #ff4081;
  --paper-pink-a400: #f50057;
  --paper-pink-a700: #c51162;

  --paper-purple-50: #f3e5f5;
  --paper-purple-100: #e1bee7;
  --paper-purple-200: #ce93d8;
  --paper-purple-300: #ba68c8;
  --paper-purple-400: #ab47bc;
  --paper-purple-500: #9c27b0;
  --paper-purple-600: #8e24aa;
  --paper-purple-700: #7b1fa2;
  --paper-purple-800: #6a1b9a;
  --paper-purple-900: #4a148c;
  --paper-purple-a100: #ea80fc;
  --paper-purple-a200: #e040fb;
  --paper-purple-a400: #d500f9;
  --paper-purple-a700: #aa00ff;

  --paper-deep-purple-50: #ede7f6;
  --paper-deep-purple-100: #d1c4e9;
  --paper-deep-purple-200: #b39ddb;
  --paper-deep-purple-300: #9575cd;
  --paper-deep-purple-400: #7e57c2;
  --paper-deep-purple-500: #673ab7;
  --paper-deep-purple-600: #5e35b1;
  --paper-deep-purple-700: #512da8;
  --paper-deep-purple-800: #4527a0;
  --paper-deep-purple-900: #311b92;
  --paper-deep-purple-a100: #b388ff;
  --paper-deep-purple-a200: #7c4dff;
  --paper-deep-purple-a400: #651fff;
  --paper-deep-purple-a700: #6200ea;

  --paper-indigo-50: #e8eaf6;
  --paper-indigo-100: #c5cae9;
  --paper-indigo-200: #9fa8da;
  --paper-indigo-300: #7986cb;
  --paper-indigo-400: #5c6bc0;
  --paper-indigo-500: #3f51b5;
  --paper-indigo-600: #3949ab;
  --paper-indigo-700: #303f9f;
  --paper-indigo-800: #283593;
  --paper-indigo-900: #1a237e;
  --paper-indigo-a100: #8c9eff;
  --paper-indigo-a200: #536dfe;
  --paper-indigo-a400: #3d5afe;
  --paper-indigo-a700: #304ffe;

  --paper-blue-50: #e3f2fd;
  --paper-blue-100: #bbdefb;
  --paper-blue-200: #90caf9;
  --paper-blue-300: #64b5f6;
  --paper-blue-400: #42a5f5;
  --paper-blue-500: #2196f3;
  --paper-blue-600: #1e88e5;
  --paper-blue-700: #1976d2;
  --paper-blue-800: #1565c0;
  --paper-blue-900: #0d47a1;
  --paper-blue-a100: #82b1ff;
  --paper-blue-a200: #448aff;
  --paper-blue-a400: #2979ff;
  --paper-blue-a700: #2962ff;

  --paper-light-blue-50: #e1f5fe;
  --paper-light-blue-100: #b3e5fc;
  --paper-light-blue-200: #81d4fa;
  --paper-light-blue-300: #4fc3f7;
  --paper-light-blue-400: #29b6f6;
  --paper-light-blue-500: #03a9f4;
  --paper-light-blue-600: #039be5;
  --paper-light-blue-700: #0288d1;
  --paper-light-blue-800: #0277bd;
  --paper-light-blue-900: #01579b;
  --paper-light-blue-a100: #80d8ff;
  --paper-light-blue-a200: #40c4ff;
  --paper-light-blue-a400: #00b0ff;
  --paper-light-blue-a700: #0091ea;

  --paper-cyan-50: #e0f7fa;
  --paper-cyan-100: #b2ebf2;
  --paper-cyan-200: #80deea;
  --paper-cyan-300: #4dd0e1;
  --paper-cyan-400: #26c6da;
  --paper-cyan-500: #00bcd4;
  --paper-cyan-600: #00acc1;
  --paper-cyan-700: #0097a7;
  --paper-cyan-800: #00838f;
  --paper-cyan-900: #006064;
  --paper-cyan-a100: #84ffff;
  --paper-cyan-a200: #18ffff;
  --paper-cyan-a400: #00e5ff;
  --paper-cyan-a700: #00b8d4;

  --paper-teal-50: #e0f2f1;
  --paper-teal-100: #b2dfdb;
  --paper-teal-200: #80cbc4;
  --paper-teal-300: #4db6ac;
  --paper-teal-400: #26a69a;
  --paper-teal-500: #009688;
  --paper-teal-600: #00897b;
  --paper-teal-700: #00796b;
  --paper-teal-800: #00695c;
  --paper-teal-900: #004d40;
  --paper-teal-a100: #a7ffeb;
  --paper-teal-a200: #64ffda;
  --paper-teal-a400: #1de9b6;
  --paper-teal-a700: #00bfa5;

  --paper-green-50: #e8f5e9;
  --paper-green-100: #c8e6c9;
  --paper-green-200: #a5d6a7;
  --paper-green-300: #81c784;
  --paper-green-400: #66bb6a;
  --paper-green-500: #4caf50;
  --paper-green-600: #43a047;
  --paper-green-700: #388e3c;
  --paper-green-800: #2e7d32;
  --paper-green-900: #1b5e20;
  --paper-green-a100: #b9f6ca;
  --paper-green-a200: #69f0ae;
  --paper-green-a400: #00e676;
  --paper-green-a700: #00c853;

  --paper-light-green-50: #f1f8e9;
  --paper-light-green-100: #dcedc8;
  --paper-light-green-200: #c5e1a5;
  --paper-light-green-300: #aed581;
  --paper-light-green-400: #9ccc65;
  --paper-light-green-500: #8bc34a;
  --paper-light-green-600: #7cb342;
  --paper-light-green-700: #689f38;
  --paper-light-green-800: #558b2f;
  --paper-light-green-900: #33691e;
  --paper-light-green-a100: #ccff90;
  --paper-light-green-a200: #b2ff59;
  --paper-light-green-a400: #76ff03;
  --paper-light-green-a700: #64dd17;

  --paper-lime-50: #f9fbe7;
  --paper-lime-100: #f0f4c3;
  --paper-lime-200: #e6ee9c;
  --paper-lime-300: #dce775;
  --paper-lime-400: #d4e157;
  --paper-lime-500: #cddc39;
  --paper-lime-600: #c0ca33;
  --paper-lime-700: #afb42b;
  --paper-lime-800: #9e9d24;
  --paper-lime-900: #827717;
  --paper-lime-a100: #f4ff81;
  --paper-lime-a200: #eeff41;
  --paper-lime-a400: #c6ff00;
  --paper-lime-a700: #aeea00;

  --paper-yellow-50: #fffde7;
  --paper-yellow-100: #fff9c4;
  --paper-yellow-200: #fff59d;
  --paper-yellow-300: #fff176;
  --paper-yellow-400: #ffee58;
  --paper-yellow-500: #ffeb3b;
  --paper-yellow-600: #fdd835;
  --paper-yellow-700: #fbc02d;
  --paper-yellow-800: #f9a825;
  --paper-yellow-900: #f57f17;
  --paper-yellow-a100: #ffff8d;
  --paper-yellow-a200: #ffff00;
  --paper-yellow-a400: #ffea00;
  --paper-yellow-a700: #ffd600;

  --paper-amber-50: #fff8e1;
  --paper-amber-100: #ffecb3;
  --paper-amber-200: #ffe082;
  --paper-amber-300: #ffd54f;
  --paper-amber-400: #ffca28;
  --paper-amber-500: #ffc107;
  --paper-amber-600: #ffb300;
  --paper-amber-700: #ffa000;
  --paper-amber-800: #ff8f00;
  --paper-amber-900: #ff6f00;
  --paper-amber-a100: #ffe57f;
  --paper-amber-a200: #ffd740;
  --paper-amber-a400: #ffc400;
  --paper-amber-a700: #ffab00;

  --paper-orange-50: #fff3e0;
  --paper-orange-100: #ffe0b2;
  --paper-orange-200: #ffcc80;
  --paper-orange-300: #ffb74d;
  --paper-orange-400: #ffa726;
  --paper-orange-500: #ff9800;
  --paper-orange-600: #fb8c00;
  --paper-orange-700: #f57c00;
  --paper-orange-800: #ef6c00;
  --paper-orange-900: #e65100;
  --paper-orange-a100: #ffd180;
  --paper-orange-a200: #ffab40;
  --paper-orange-a400: #ff9100;
  --paper-orange-a700: #ff6500;

  --paper-deep-orange-50: #fbe9e7;
  --paper-deep-orange-100: #ffccbc;
  --paper-deep-orange-200: #ffab91;
  --paper-deep-orange-300: #ff8a65;
  --paper-deep-orange-400: #ff7043;
  --paper-deep-orange-500: #ff5722;
  --paper-deep-orange-600: #f4511e;
  --paper-deep-orange-700: #e64a19;
  --paper-deep-orange-800: #d84315;
  --paper-deep-orange-900: #bf360c;
  --paper-deep-orange-a100: #ff9e80;
  --paper-deep-orange-a200: #ff6e40;
  --paper-deep-orange-a400: #ff3d00;
  --paper-deep-orange-a700: #dd2c00;

  --paper-brown-50: #efebe9;
  --paper-brown-100: #d7ccc8;
  --paper-brown-200: #bcaaa4;
  --paper-brown-300: #a1887f;
  --paper-brown-400: #8d6e63;
  --paper-brown-500: #795548;
  --paper-brown-600: #6d4c41;
  --paper-brown-700: #5d4037;
  --paper-brown-800: #4e342e;
  --paper-brown-900: #3e2723;

  --paper-grey-50: #fafafa;
  --paper-grey-100: #f5f5f5;
  --paper-grey-200: #eeeeee;
  --paper-grey-300: #e0e0e0;
  --paper-grey-400: #bdbdbd;
  --paper-grey-500: #9e9e9e;
  --paper-grey-600: #757575;
  --paper-grey-700: #616161;
  --paper-grey-800: #424242;
  --paper-grey-900: #212121;

  --paper-blue-grey-50: #eceff1;
  --paper-blue-grey-100: #cfd8dc;
  --paper-blue-grey-200: #b0bec5;
  --paper-blue-grey-300: #90a4ae;
  --paper-blue-grey-400: #78909c;
  --paper-blue-grey-500: #607d8b;
  --paper-blue-grey-600: #546e7a;
  --paper-blue-grey-700: #455a64;
  --paper-blue-grey-800: #37474f;
  --paper-blue-grey-900: #263238;

  /* opacity for dark text on a light background */
  --dark-divider-opacity: 0.12;
  --dark-disabled-opacity: 0.38;
  /* or hint text or icon */
  --dark-secondary-opacity: 0.54;
  --dark-primary-opacity: 0.87;

  /* opacity for light text on a dark background */
  --light-divider-opacity: 0.12;
  --light-disabled-opacity: 0.3;
  /* or hint text or icon */
  --light-secondary-opacity: 0.7;
  --light-primary-opacity: 1;
}
.card {
  margin: 24px;
  padding: 16px;
  color: #757575;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #555;
  border-radius: 50%;
  background: #ddd;
}

:root {
  --app-primary-dark-color: #1e88e5;
  --app-primary-color: #2196f3;
  --app-accent-color: #00bcda;
  --app-text-color1: #181616;
  --app-text-color: #424242;
  --app-text-normal-color: #616161;
  --app-text-float-color: #9e9e9e;
  --app-text-fa-color: #fafafa;

  \--app-text-overflow-one: {
    display: inline-block !important;
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  \--app-admin-title: {
    color: #424242;
    color: var(--app-text-color);
    margin: 0;
  }
}

.color_principale {
  color: #2196f3 !important;
  color: var(--app-primary-color) !important;
}

paper-input {
  \--paper-input-container-disabled: {
    opacity: 0.73 !important;
    /*--paper-input-container-label: {
      color: red;
    }
    --paper-input-container-color: {
      color: blue;
    }
    --paper-input-container-underline-disabled: {
      /*display: none;*/
  }
}

select {
  width: 100%;
  height: 35px;
  border-width: 0px;
  border-bottom: 1px solid gray;
  border-radius: 0px;
  color: black;
}

select[disabled] {
  opacity: 0.73 !important;
  border-bottom: 1px dashed gray;
}

select:focus {
  border-top-color: transparent;
  border-bottom: 2px solid #3f51b5;
  outline: none;
  color: black;
}

a:active {
  color: #000;
  color: initial;
}

.red_link {
  color: red;
}

.green_link {
  color: green;
}

.grey_link,
.grey_icon {
  color: #616161;
  color: var(--app-text-normal-color);
}

.grey_link:hover {
  color: #424242;
  color: var(--app-text-color);
}

.principale_link {
  color: #2196f3;
  color: var(--app-primary-color);
}

.principale_link:hover {
  color: #1e88e5;
  color: var(--app-primary-dark-color);
}

.normal_link {
  color: #0b0b86;
}

.normal_link:hover {
  color: #0b0b86;
}

.button-grey-grey {
  --paper-button-ink-color: var(--paper-grey-300) !important;
  background-color: #eeeeee !important;
  background-color: var(--paper-grey-200) !important;
  color: #181616 !important;
  color: var(--app-text-color1) !important;
}

.button-grey-grey-mini {
  --paper-button-ink-color: var(--paper-grey-300) !important;
  background-color: #eeeeee !important;
  background-color: var(--paper-grey-200) !important;
  color: #181616 !important;
  color: var(--app-text-color1) !important;
  height: 30px;
  font-size: small;
}

.button-orange {
  --paper-button-ink-color: var(--paper-orange-400) !important;
  background-color: white !important;
  color: #f57c00;
  color: var(--paper-orange-700);
}

.button-principale {
  --paper-button-ink-color: var(--app-accent-color) !important;
  background-color: #1e88e5 !important;
  background-color: var(--app-primary-dark-color) !important;
  color: white !important;
}

.button-principale-mini {
  --paper-button-ink-color: var(--app-accent-color) !important;
  background-color: #1e88e5 !important;
  background-color: var(--app-primary-dark-color) !important;
  color: white !important;
  height: 30px;
  font-size: small;
}

.button-principale-red {
  --paper-button-ink-color: var(--paper-red-300) !important;
  background-color: #f44336 !important;
  background-color: var(--paper-red-500) !important;
  color: white !important;
}

.button-principale-red-mini {
  --paper-button-ink-color: var(--paper-red-300) !important;
  background-color: #f44336 !important;
  background-color: var(--paper-red-500) !important;
  color: white !important;
  height: 30px;
  font-size: small;
}

.button-grey-colored {
  --paper-button-ink-color: var(--app-primary-dark-color) !important;
  background-color: #eeeeee !important;
  background-color: var(--paper-grey-200) !important;
  color: #2196f3 !important;
  color: var(--app-primary-color) !important;
}

.button-white-colored {
  --paper-button-ink-color: var(--app-primary-dark-color) !important;
  background-color: white !important;
  color: #2196f3 !important;
  color: var(--app-primary-color) !important;
}

.button-white-grey {
  --paper-button-ink-color: var(--paper-grey-300) !important;
  background-color: white !important;
  color: #424242 !important;
  color: var(--app-text-color) !important;
}

.button-mini-grey1 {
  --paper-button-ink-color: var(--paper-grey-300) !important;
  background-color: #eeeeee !important;
  background-color: var(--paper-grey-200) !important;
  color: #616161 !important;
  color: var(--app-text-normal-color) !important;
  height: 30px;
  font-size: small;
}

.button-mini-grey2 {
  --paper-button-ink-color: var(--paper-grey-300) !important;
  /*background-color: var(--paper-grey-200) !important;*/
  color: #616161 !important;
  color: var(--app-text-normal-color) !important;
  height: 30px;
  font-size: small;
}

.button-principale iron-icon,
.button-principale-red iron-icon {
  width: 20px;
  height: 20px;
}

.button-principale-mini iron-icon,
.button-principale-red-mini iron-icon,
.button-mini-grey1 iron-icon,
.button-mini-grey2 iron-icon {
  width: 15px;
  height: 15px;
}

.no-underline {
  text-decoration: none !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.transform_none {
  text-transform: none !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.no-radius {
  border-radius: 0 !important;
}

.relative_time {
  font-size: x-small;
}

.mirror {
  /* Gecko */
  /* Opera */
  -webkit-transform: scaleX(-1);
  /* Webkit */
  transform: scaleX(-1);
  /* Standard */
  -webkit-filter: FlipH;
          filter: FlipH;
  /* IE 6/7/8 */
}

.mirror_share {

  /* Gecko */

  /* Opera */
  -webkit-transform: scaleX(-1) rotateZ(20deg);

  /* Webkit */
  transform: scaleX(-1) rotateZ(20deg);

  /* Standard */
  -webkit-filter: FlipH rotateZ(20deg);
          filter: FlipH rotateZ(20deg);

  /* IE 6/7/8 */
}

.spinner.multi {
  --paper-spinner-layer-1-color: var(--paper-purple-500);
  --paper-spinner-layer-2-color: var(--paper-cyan-500);
  --paper-spinner-layer-3-color: var(--paper-blue-grey-500);
  --paper-spinner-layer-4-color: var(--paper-amber-500);
}

paper-icon-button.principale.icon {
  --paper-icon-button-disabled-text: var(--paper-grey-300);
  --paper-icon-button-ink-color: var(--app-accent-color);
  color: #2196f3;
  color: var(--app-primary-color);
}

paper-icon-button.grey.icon {
  --paper-icon-button-disabled-text: var(--paper-grey-300);
  --paper-icon-button-ink-color: var(--app-accent-color);
  color: #424242;
  color: var(--app-text-color);
}

paper-icon-button.blue.icon {
  --paper-icon-button-disabled-text: var(--paper-grey-300);
  --paper-icon-button-ink-color: var(--paper-blue-300);
  color: #2196f3;
  color: var(--paper-blue-500);
}

paper-icon-button.green.icon {
  --paper-icon-button-disabled-text: var(--paper-grey-300);
  --paper-icon-button-ink-color: var(--paper-green-300);
  color: #4caf50;
  color: var(--paper-green-500);
}

paper-icon-button.red.icon {
  --paper-icon-button-disabled-text: var(--paper-red-300);
  --paper-icon-button-ink-color: var(--paper-red-300);
  color: #f44336;
  color: var(--paper-red-500);
}

paper-icon-button.white.icon {
  --paper-icon-button-disabled-text: var(--paper-grey-300);
  --paper-icon-button-ink-color: var(--app-primary-dark-color);
  color: white;
}

a.inherit {
  color: inherit;
}

paper-progress {
  width: 100% !important;
}

div.layout-container-title {
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}

div.layout-footer {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}

div.layout-content {
  padding: 10px;
  background-color: white;
}

div.page-title {
  font-size: 21px;
  line-height: 22px;
  font-weight: 400;
}

div.page-subtitle {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  color: #2196f3;
  color: var(--app-primary-color);
}

paper-toggle-button {
  --paper-toggle-button-unchecked-ink-color: var(--app-primary-dark-color);
  --paper-toggle-button-checked-button-color: var(--app-primary-color);
  --paper-toggle-button-checked-ink-color: var(--app-primary-color);
}

paper-progress-button {
  \--paper-progress-button-button: {
    border-radius: 0 !important;
    text-transform: none;
    --paper-button-ink-color: var(--paper-grey-300) !important;
    background-color: #eeeeee !important;
    background-color: var(--paper-grey-200) !important;
    color: #616161 !important;
    color: var(--app-text-normal-color) !important;
    padding: 0.4em 0.57em !important;
    width: 100%;
    margin: 0 !important;
  }

  \--paper-progress-button-button-active: {
    --paper-button-ink-color: var(--app-accent-color) !important;
    background-color: #1e88e5 !important;
    background-color: var(--app-primary-dark-color) !important;
    color: white !important;
  }

  \--paper-progress-button-spinner: {
    --paper-spinner-color: var(--app-primary-color);
  }
}

paper-button {
  \--paper-button: {
    padding: 0.4em 0.57em !important;
  }
}

date-picker,
datetime-picker {
  color: white;
  --input-color: var(--app-text-color);
  --input-background: white;
  --input-focus-color: #000;
  --input-focus-background: white;
  --inner-input-focus-color: var(--app-text-normal-color);
  --inner-input-focus-background: var(--app-text-fa-color);
  --input-invalid-color: #e8395e;
  --input-padding: 0.25em;
  --input-border-radius: 0;
  --input-border-color: rgba(0, 0, 0, 0.2);
  --input-border-width: thin;
  --input-border-style: dotted;
  --input-transition: background 150ms ease-in-out;
  --inner-input-focus-border-color: rgba(0, 0, 0, 0.2);

  --input-focus-border-color: var(--app-primary-color);
  --input-focus-border-width: thin;
  --input-focus-border-style: dotted;
  --input-picker-color: white;
  --input-picker-background: var(--app-primary-color);
  --input-picker-border-radius: 0;
  --input-placeholder-color: rgba(0, 0, 0, 0.5);

  \--calendar-cell-hovered: {
    border-color: rgba(0, 0, 0, 0.5);
  }
}

.floating_menu {
  position: fixed;
  bottom: 0;
  right: 30px;
}

.floating_menu {
  \--paper-menu-button-content: {
    padding-bottom: 10px;
    background-color: transparent;
    box-shadow: none;
  }
}

paper-fab.principale.rotate {
  transition-duration: 400ms;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

paper-fab.principale.no-rotate {
  transition-duration: 400ms;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

paper-fab.principale {
  --paper-fab-background: var(--app-primary-dark-color);
  --paper-fab-keyboard-focus-background: var(--app-accent-color);
}

paper-input {
  \--paper-input-container: {
  }
}

.floating_menu .fab_title {
  display: block;
  outline: none;
  font-size: 10px;
  line-height: 1;
  background-color: #616161;
  color: white;
  padding: 8px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
}

paper-fab[mini].principale {
  \--paper-fab-iron-icon: {
    width: 19px;
    height: 19px;
  }
}

.error_message {
  margin: 3px;
  color: red;
  /* font-weight: bold; */
  font-size: 13px;
  border: 1px solid #eaeaea;
  padding: 5px;
  background-color: #eeee;
}

.error_message ul {
  margin: 0 !important;
}

.title3 {
  color: #2196f3;
  color: var(--app-primary-color);
  font-weight: 400;
  /* line-height: 0; */
  font-size: 20px;
}

.title6 {
  color: gray;
  font-weight: 400;
  font-size: 11px;
}

.button_principale2 {
  --paper-button-ink-color: var(--app-primary-dark-color) !important;
  background-color: #2196f3 !important;
  background-color: var(--app-primary-color) !important;
  color: white !important;
  font-size: medium;
}

.trumbowyg-box,
.trumbowyg-editor,
.trumbowyg-textarea {
  min-height: 100px !important;
  background-color: white;
}

.trumbowyg-fullscreen {
  top: 64px !important;
}

p {
  margin: 0 0 10px;
}

.popover_container {
  cursor: pointer;
  position: relative;
}

.user_sub_title {
  font-weight: 500;
  color: #4c4c4c;
  font-size: 11px;
  line-height: 1.5;
}

/*Flex layout*/

/*Most common used flex styles*/

.layout.horizontal,
.layout.vertical {
  display: -webkit-flex;
  display: flex;
}

.layout.inline {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.layout.horizontal {
  -webkit-flex-direction: row;
  flex-direction: row;
}

.layout.vertical {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.layout.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.layout.no-wrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.layout.center,
.layout.center-center {
  -webkit-align-items: center;
  align-items: center;
}

.layout.center-justified,
.layout.center-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flex {
  -webkit-flex: 1;
  flex: 1 1;
  -webkit-flex-basis: 0.000000001px;
          flex-basis: 0.000000001px;
}

.flex-auto {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-none {
  -webkit-flex: none;
  flex: none;
}

/* Basic flexbox reverse styles*/
.layout.horizontal-reverse,
.layout.vertical-reverse {
  display: -webkit-flex;
  display: flex;
}

.layout.horizontal-reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.layout.vertical-reverse {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.layout.wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
/*Flexbox alignment*/

/**
       * Alignment in cross axis.
       */
.layout.start {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.layout.center,
.layout.center-center {
  -webkit-align-items: center;
  align-items: center;
}

.layout.end {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.layout.baseline {
  -webkit-align-items: baseline;
  align-items: baseline;
}

/**
       * Alignment in main axis.
       */
.layout.start-justified {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.layout.center-justified,
.layout.center-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.layout.end-justified {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.layout.around-justified {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.layout.justified {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/**
       * Self alignment.
       */
.self-start {
  -ms-align-self: flex-start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.self-center {
  -ms-align-self: center;
  -webkit-align-self: center;
  align-self: center;
}

.self-end {
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}

.self-stretch {
  -ms-align-self: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.self-baseline {
  -ms-align-self: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
}

/**
       * multi-line alignment in main axis.
       */
.layout.start-aligned { /* IE10 */
  -ms-align-content: flex-start;
  -webkit-align-content: flex-start;
          align-content: flex-start;
}

.layout.end-aligned { /* IE10 */
  -ms-align-content: flex-end;
  -webkit-align-content: flex-end;
          align-content: flex-end;
}

.layout.center-aligned { /* IE10 */
  -ms-align-content: center;
  -webkit-align-content: center;
          align-content: center;
}

.layout.between-aligned { /* IE10 */
  -ms-align-content: space-between;
  -webkit-align-content: space-between;
          align-content: space-between;
}

.layout.around-aligned { /* IE10 */
  -ms-align-content: space-around;
  -webkit-align-content: space-around;
          align-content: space-around;
}
/*iron-flex-factors*/

.flex,
.flex-1 {
  -webkit-flex: 1;
  flex: 1 1;
  -webkit-flex-basis: 0.000000001px;
          flex-basis: 0.000000001px;
}

.flex-2 {
  -webkit-flex: 2;
  flex: 2 1;
}

.flex-3 {
  -webkit-flex: 3;
  flex: 3 1;
}

.flex-4 {
  -webkit-flex: 4;
  flex: 4 1;
}

.flex-5 {
  -webkit-flex: 5;
  flex: 5 1;
}

.flex-6 {
  -webkit-flex: 6;
  flex: 6 1;
}

.flex-7 {
  -webkit-flex: 7;
  flex: 7 1;
}

.flex-8 {
  -webkit-flex: 8;
  flex: 8 1;
}

.flex-9 {
  -webkit-flex: 9;
  flex: 9 1;
}

.flex-10 {
  -webkit-flex: 10;
  flex: 10 1;
}

.flex-11 {
  -webkit-flex: 11;
  flex: 11 1;
}

.flex-12 {
  -webkit-flex: 12;
  flex: 12 1;
}

/*Non-flexbox positioning helper styles*/

.block {
  display: block;
}

[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.relative {
  position: relative;
}

.fit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body.fullbleed {
  margin: 0;
  height: 100vh;
}

.scroll {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

/* fixed position */
.fixed-bottom,
.fixed-left,
.fixed-right,
.fixed-top {
  position: fixed;
}

.fixed-top {
  top: 0;
  left: 0;
  right: 0;
}

.fixed-right {
  top: 0;
  right: 0;
  bottom: 0;
}

.fixed-bottom {
  right: 0;
  bottom: 0;
  left: 0;
}

.fixed-left {
  top: 0;
  bottom: 0;
  left: 0;
}


.App {
  /* text-align: center; */

}
.App-content{
  /* margin-top:64px; */
  overflow: auto;
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 1024px !important;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Error-message {
  color: red;
  font-size: 13px
}
.MuiIconButton-edgeEnd{
  margin-right: 0 !important;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.error_field{
  color:red;
}

